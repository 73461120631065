import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import SectionMedia from '../SectionMedia';

import { formColor } from '../../../../helper';

import styles from './styles.module.css';

class MeetTeamLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';
    const layout = props.section.styles.layout ? props.section.styles.layout : 'left';

    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      layout,
      align,
      full,
      columns,
    };

  }

  render() {

    const lay = this.state.layout === 'round'
      ? 'Round'
      : this.state.layout === 'square'
        ? 'Square' : this.state.layout === 'noGutters' ? 'NoGutters' : '';
    const teamTileWrapper = `teamTile${lay}Wrapper`;
    const teamImgWrapper = `teamImgWrapper${lay}`;
    const imgWrapper = `teamImg${lay}`;
    const teamInfoWrapper = `teamInfoWrapper${lay}`;
    const rowWrapper = `row${this.state.layout === 'noGutters' ? ' no-gutters' : ''}`;
    const spacing = this.state.layout === 'round'
      ? { padding: '20px 10px' }
      : this.state.layout === 'square'
        ? { marginLeft: '10px', marginRight: '10px', paddingBottom: '20px' } : undefined;

    const activeComponents = [];
    const components = [];
    this.props.section.data.map((item, index) => {

      if (item.type === 'COMPONENT' && item.active) {

        activeComponents.push({ item, index });

      }

      return null;

    });

    activeComponents.map(item => {

      let style;
      if (item.item.styles.backgroundColor.active) {

        let { backgroundColor } = item.item.styles;
        if (
          item.item.styles.backgroundColor.solid === ''
          && item.item.styles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#ffffff' };

        }

        style = formColor(
          backgroundColor,
          false,
          item.item.styles.backgroundColor.opacity,
        );

      }

      const texts = [];
      let image;
      const noGutters = this.state.layout === 'noGutters' && !item.item.data[0].active;
      item.item.data.forEach((elem, i) => {

        if (elem.active && elem.type === 'IMAGES/IMAGE') {

          image = (
            <div className={styles[imgWrapper]}>
              <SectionMedia
                mediaType="IMAGE"
                elementStyle={imgWrapper}
                wrapperStyle="teamImgWrapper"
                src={elem.content.src}
                alt={elem.content.alt}
                data={elem.content}
                images={this.props.images}
                pagePathList={this.props.pagePathList}
                articlePathList={this.props.articlePathList}
                filePathList={this.props.filePathList}
              />
            </div>
          );

        } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

          let data;
          if (elem.type === 'HEADINGS/HEADING-THREE') data = `<h3>${elem.text}</h3>`;
          else data = `<span>${elem.text}</span>`;

          const text = (
            <div
              key={`${this.props.section._id}_elem_${i}`}
              className={`${styles.teamImageText}  ${styles[`teamText${item.item.align}`]}`}
            >
              { HTMLParser(data) }
            </div>
          );

          texts.push(text);

        }

      });

      const teamInfoElement = texts.length > 0
        ? (
          <div
            className={styles[teamInfoWrapper]}
            style={
              this.state.layout === 'square'
                ? spacing
                : noGutters
                  ? { display: 'flex', background: 'initial' } : undefined
            }
          >
            {texts}
          </div>
        )
        : undefined;

      const newElement = (
        <div
          key={`${this.props.section._id}_layout_${item.index}`}
          className={this.state.columns === 1
            ? `col-12 ${styles[teamTileWrapper]}`
            : `col-12 col-sm-6 col-lg-${this.state.columns ? (12 / this.state.columns) : 4} ${styles[teamTileWrapper]}`
          }
        >
          <div
            style={
              this.state.layout === 'round' && style
                ? { ...style, ...spacing }
                : noGutters
                  ? { ...style, ...{ display: 'flex' } } : style
            }
            className={styles.tileContent}
          >
            {
              image
              && (
                <div className={styles[teamImgWrapper]}>
                  {
                    this.state.layout === 'noGutters'
                    && (
                      <div className={styles.teamHoverNoGutters}>
                        {teamInfoElement}
                      </div>
                    )
                  }
                  { image }
                </div>
              )
            }
            {(this.state.layout !== 'noGutters' || noGutters) && teamInfoElement}
          </div>
        </div>
      );

      components.push(newElement);

      return null;

    });

    const headerLay = `teamHeader${this.state.layout === 'square' || this.state.layout === 'round' ? 'Alt' : this.state.layout === 'noGutters' ? 'NoGutters' : ''}`;
    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`teamContainer${this.state.full}`]}`}>
        {
          this.props.section.data.map((elem, i) => {

            let result;
            if (elem.active && elem.type === 'HEADINGS/HEADING-TWO') {

              result = (
                <div
                  key={`${this.props.section._id}_elem_${i}`}
                  className={`col-12 ${styles[headerLay]} ${styles[alignStyle]}`}
                >
                  { HTMLParser(`<h2>${elem.text}</h2>`) }
                </div>
              );

            }

            return result;

          })
        }
        <div className={`${rowWrapper} ${styles[alignStyle]} ${styles.teamRowWrapper}`}>
          {components}
        </div>
      </div>
    );

  }

}

MeetTeamLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    })),
  }),
};

export default MeetTeamLayout;
